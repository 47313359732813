/* You can add global styles to this file, and also import other style files */
body{
    margin:0;
    padding:0;
    box-sizing: border-box;
    width: 100%;
    background: url('../public/assets/imgs/bg.svg') center center / cover no-repeat;
    min-height: 100vh;
}

.img-container{
    max-width: 500px;
    width: 100%;
    max-height:100px;
    overflow: hidden;
}

